import VueRouter from "vue-router";
import store from '../store'
import {CHECK_AUTH} from "@/store/actions.type";


const requireAuth = (role) =>  {
  return function (to, from, next) {
    store.dispatch(CHECK_AUTH)
        .then(user=>{
          if(user.role > role) {
            return next({
              path: '/'
            })
          }
          next()
        })
        .catch(() => next({
          path: '/login'
        }))
  }
}

const routes = [
    {
        path: "/login",
        name: "login",
        meta: {disableNavbar: true},
        component: () => import("@/views/login")
    },
    {
        path: "/",
        name: "home",
        beforeEnter: requireAuth(1),
        component: () => import("@/views/home")
    },
    {
        path: "/test",
        name: "test",
        beforeEnter: requireAuth(1),
        component: () => import("@/views/test")
    },
    {
        path: "/invoice/:id",
        name: "invoice",
        beforeEnter: requireAuth(1),
        component: () => import("@/views/claim/ocr")
    },
    {
        path: "/claim",
        beforeEnter: requireAuth(1),
        component: () => import("@/views/claim/index"),
        children: [
            {
                path: 'profile',
                component: () => import("@/views/claim/profile"),
            },
            {
                path: 'invoices',
                component: () => import("@/views/claim/invoices"),
            },
            {
                path: 'simulation',
                component: () => import("@/views/claim/simulation"),
            },
            {
                path: 'end',
                component: () => import("@/views/claim/end"),
            },

        ],

    },

    {
        path: "/demo",
        name: "demo",
        beforeEnter: requireAuth(1),
        component: () => import("@/views/demo")
    },

];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
