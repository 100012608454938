import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "../store"
import {LOGOUT, ON_FORBIDDEN} from "@/store/actions.type";
import queryString from 'query-string';
import {cleanObject, logError} from "@/helpers";

const ID_TOKEN_KEY = "id_token";
const API_URL = process.env.VUE_APP_API_URL

const withPromise = axiosInstance =>
    new Promise((resolve, reject) => {
        axiosInstance
            .then(res => resolve(res.data))
            .catch(err => {
                    // service is unavailable
                    if (!err.response) {
                        logError("Service is unavailable")
                        return reject("Service is unavailable")
                    }
                    if(err.response.data && err.response.data.message) {
                        logError(err.response.data.message)
                    }
                    if (err.response.status === 403) {
                        // permission is denied
                        store.dispatch(ON_FORBIDDEN)
                    } else if (err.response.status === 401) {
                        store.dispatch(LOGOUT)
                    }
                    reject(err.response)
                }
            )
    })

const ApiService = {

    API_URL,


    initialize() {
        const token = ApiService.getToken()
        if (token) {
            Vue.axios.defaults.headers.common["Authorization"] = token;
        }else{
            delete Vue.axios.defaults.headers.common["Authorization"]
        }
    },

    stringifyUrl(url, obj) {
        const query = cleanObject(obj)
        return queryString.stringifyUrl({url, query });
    },

    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
    },

    get(url, config) {
        ApiService.initialize()
        return withPromise(Vue.axios.get(url,config))
    },

    post(url, data, config) {
        ApiService.initialize()
        return withPromise(Vue.axios.post(url,data,config))
    },

    put(url, data, config) {
        ApiService.initialize()
        return withPromise(Vue.axios.put(url, data, config));
    },

    delete(url, config) {
        ApiService.initialize()
        return withPromise(Vue.axios.delete(url, config));
    },

    //post or put
    upsert(url, obj) {
        const { _id } = obj
        const fn = _id ? ApiService.put : ApiService.post
        const _url = `${url}${_id ? `/${_id}` : ''}`
        return fn(_url, obj)
    },




    download(url, filename) {
        ApiService.initialize()
        Vue.axios.get(url,{
            responseType: 'blob',
        }).then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    },

    uploadFile (file, url, config) {
        const formData = new FormData()
        formData.append('file', file, file.name)
        ApiService.initialize()
        return withPromise(Vue.axios.post(url, formData, config))
    },

    //use this to remove while testing: localStorage.removeItem('id_token');
    saveToken(token) {
        window.localStorage.setItem(ID_TOKEN_KEY, token);
    },

    destroyToken() {
        window.localStorage.removeItem(ID_TOKEN_KEY);
    },

    getToken() {
        return window.localStorage.getItem(ID_TOKEN_KEY);
    }


};

export default ApiService;
