<template>
  <div id="app" class="main-app">
    <nav-bar v-if="!this.$route.meta.disableNavbar"></nav-bar>
    <div class="main-app-content">
      <router-view />
    </div>

  </div>
</template>

<script>
  import navBar from "@/components/navbar";
  import {LOAD_ACTIVE_CLAIM} from "@/store/actions.type";

  export default {
    name: "App",
    components: {
      navBar
    },
    async mounted() {
      await this.$store.dispatch(LOAD_ACTIVE_CLAIM)
    }
  };
</script>

<style>
/*vueform theme must be imported here in Vue2*/
@import './../node_modules/@vueform/vueform/themes/vueform/css/index.min.css';

@font-face {
  font-family: 'reclamatec';
  src: url('./assets/AvenirLTStd-Roman.ttf');
}

.main-app-content {
  margin-top: 37px;
}
</style>
