import ApiService from "@/services/api.service";
import _ from 'lodash'
import router from '../router'

import {
    LOGIN,
    CHECK_AUTH,
    LOGOUT,
    ON_FORBIDDEN, RESET_CLAIM, LOAD_ACTIVE_CLAIM,
} from "./actions.type";
import { SET_AUTH, PURGE_AUTH} from "./mutations.type";

const state = {
  user: {}
};


const getters = {
    isAuthenticated(state) {
        return state.user && !_.isEmpty(state.user);
    },
    isAdmin(state) {
        return state.user && state.user.role === 'admin';
    },
    user(state) {
        return state.user;
    },
}

const safeRedirect = (to) => {
    if(router.currentRoute.path !== to){
        return router.push(to)
    }
    return Promise.resolve()
}

const actions = {

    async [LOGIN](context, { captcha,...credentials }) {
        await context.commit(PURGE_AUTH)
        const data = await ApiService.post("api/auth/login", credentials, {
            headers: { captcha }
        })
        await context.commit(SET_AUTH, data);
        await context.dispatch(LOAD_ACTIVE_CLAIM)
        return safeRedirect("/")

    },

    [CHECK_AUTH](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("api/auth/whoami")
                .then((data)=>{
                    context.commit(SET_AUTH, data);
                    resolve(data)
                })
                .catch(()=>{
                    context.commit(PURGE_AUTH);
                    reject()
                })

        })

    },

    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
      return safeRedirect('/login')
    },


    [ON_FORBIDDEN]() { //redirect home
      return safeRedirect('/')
    },
};

const mutations = {
  [SET_AUTH](state, {user, token}) {
    if(token) {
        ApiService.saveToken(token)
    }
    state.user = user;

  },
  [PURGE_AUTH](state) {
    ApiService.destroyToken()
    state.user = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
