import ApiService from "@/services/api.service";
import _ from 'lodash'
import {
    LOAD_ACTIVE_CLAIM,
    PUSH_CLAIM,
    SAVE_PROFILE,
    SAVE_INVOICE,
    GO_TO_STEP,
    RESET_CLAIM,
    STEPS_ENUM,
    SAVE_SIMULATION, SAVE_CLAIM_ID
} from './actions.type'


const fromObject = (obj) => {
    const _obj = obj || {}
    return {
        _id: _.get(_obj, '_id',null),
        finished: _.get(_obj, 'finished',false),
        activeStep: _.get(_obj, 'activeStep',1),
        lastStep: _.get(_obj,'lastStep',1),
        simulation: null,
        claim: {
            profile: _.get(_obj,'claim.profile',null),
            invoices: _.get(_obj,'claim.invoices',[])
        }
    }
}


const state = fromObject(null);


const getters = {
    claim(state) {
        return state;
    },
    claimStarted(state) {
        return state.lastStep > STEPS_ENUM.PROFILE
    },
    activeStep(state) {
        return state.activeStep
    },
    lastStep(state) {
        return state.lastStep
    }

}


const actions = {
    async [LOAD_ACTIVE_CLAIM](context) {
        const { data } = await ApiService.get('api/claim/active')
        await context.commit(LOAD_ACTIVE_CLAIM, data)
    },

    async [SAVE_INVOICE](context, data) {
        await context.commit(SAVE_INVOICE, data)
        await context.dispatch(PUSH_CLAIM)
    },

    async [SAVE_PROFILE](context, data) {
        const profile = await ApiService.upsert('api/profile',data)
        await context.commit(SAVE_PROFILE, profile)
        await context.dispatch(PUSH_CLAIM)
        await context.dispatch(GO_TO_STEP, STEPS_ENUM.INVOICES)
    },

    async [PUSH_CLAIM](context) {
        const { _id } = await ApiService.upsert('api/claim', context.state)
        await context.commit(SAVE_CLAIM_ID, _id)
    },


    async [GO_TO_STEP](context, data) {
        await context.commit(GO_TO_STEP, data)
        const { activeStep, lastStep, _id } = context.state
        await ApiService.upsert('api/claim', { activeStep, lastStep, _id })
    },

    async [RESET_CLAIM](context, data) {
        await context.commit(RESET_CLAIM, data)
    },

    async [SAVE_SIMULATION](context, data) {
        const { _id } = state
        await ApiService.post(`api/claim/${_id}/simulation`, data);
        await context.commit(SAVE_SIMULATION, data)
        await context.dispatch(GO_TO_STEP, STEPS_ENUM.END)
    },


};

const mutations = {


    async [LOAD_ACTIVE_CLAIM](state, data) {
        Object.assign(state, fromObject( data ))
    },

    async [SAVE_PROFILE](state, data) {
        state.claim.profile = data
    },

    async [SAVE_SIMULATION](state, simulation) {
        state.simulation = simulation
    },

    async [SAVE_INVOICE](state, data) {
        state.claim.invoices.push(data);
    },

    async [SAVE_CLAIM_ID](state, _id) {
        state._id = _id
    },

    async [GO_TO_STEP](state, step) {
        state.activeStep = step
        if(state.lastStep < step) {
            state.lastStep = step
        }
    },
    async [RESET_CLAIM](state) {
        Object.assign(state, fromObject(null))

    },
}

export default {
  state,
  actions,
  mutations,
  getters
};
