import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import {
   TooltipPlugin,
   AlertPlugin,
   CollapsePlugin,
   BadgePlugin,
   NavbarPlugin,
   DropdownPlugin,
   IconsPlugin,
   PaginationPlugin,
   TablePlugin,
   ButtonPlugin,
   FormCheckboxPlugin,
} from 'bootstrap-vue'

import VueToastr from "vue-toastr";
import Vueform from '@vueform/vueform'
import vueformConfig from './vueform.config'
import PrimeVue from 'primevue/config';
import VueRouter from "vue-router";
import ApiService from "./services/api.service";
import Message from 'primevue/message';
import Tag from '@/components/tag'
import ConfirmationService from 'primevue/confirmationservice';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/rhea/theme.css';
import 'primeicons/primeicons.css';
import 'animate.css';

// Importing the global css file
import "@/assets/global.css"

// Register Plugins
Vue.use(VueRouter);
// Vue Bootstrap
Vue.use(IconsPlugin)
Vue.use(TooltipPlugin)
Vue.use(NavbarPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(BadgePlugin)
Vue.use(AlertPlugin)
Vue.use(PaginationPlugin)
Vue.use(TablePlugin)
Vue.use(ButtonPlugin)
Vue.use(FormCheckboxPlugin)

Vue.use(VueToastr, {});
Vue.use(Vueform, vueformConfig);
Vue.use(PrimeVue);
Vue.use(ConfirmationService);

// Component and directive registration
Vue.component('Message', Message)
Vue.component('Tag', Tag)


Vue.config.productionTip = false;

ApiService.init();

new Vue({
   router,
   store,
   render: h => h(App),
   async mounted() {
   },
}).$mount("#app");
