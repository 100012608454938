// remove attr from object with null values

import {LOG} from "./store/actions.type";
import store from './store'
import _ from 'lodash'
import moment from "moment";

export const cleanObject = function(obj) {
    return Object.keys(obj).reduce((prev,k)=> {
        if (obj[k] != null) {
            prev[k] = obj[k]
        }
        return prev
    },{})
}

// convert array of objets into a usable object structure.
// Prop must be uniq in all objects, otherwise will be overwritten
export const mapifyArray = function (arr, prop) {
    return arr.reduce((prev, val)=>{
        prev[val[prop]] = val
        return prev
    },{})
}
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

export class Debouncer {
    constructor () {
        this.timeout = null;
    }

    debounce(context, func, wait) {
        let args = arguments;
        const self = this

        const later = function () {
            self.timeout = null;
            func.apply(context, args)
        };

        if (self.timeout) clearTimeout(self.timeout)
        self.timeout = setTimeout(later, wait);


    }
}

export const listToDict = (data, prop) => {
    return (data || []).reduce((prev, el) => {
        prev[el[prop]] = el
        return prev
    },{})
}

export const formarCurrencyNumber = (str) => {
    if(!str) return '-'
    const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
    });
    return formatter.format(str)
}

export const formarPctNumber = (str) => {
    if(!str) return '-'
    const formatter = new Intl.NumberFormat('es-ES', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(str)
}


export const toDateStr = (date) => {
    return  moment(date).format("YYYY-MM-DD HH:mm:ss");
}




export const roundDecimal = function (num, dec) {
    if(!num) return num
    const mult = Math.pow(10, dec || 2)
    return Math.round(num * mult, 2)/mult
}

export const dateDiffStr = function (f, t) {
    const now = moment(f);
    const end = moment(t);
    const duration = moment.duration(end.diff(now));
    

    let days =  Math.floor(duration.asDays());
    duration.subtract(moment.duration(days,'days'));

    let hours = duration.hours();
    duration.subtract(moment.duration(hours,'hours'));

    let minutes = duration.minutes();
    duration.subtract(moment.duration(minutes,'minutes'));

    return [days, hours, minutes]
}


// compares unordered array of strings
export const compareArrays = function (arr1,arr2) {
    return _.isEqual(arr1.sort(), arr2.sort())
}

export const logInfo = function (msg) {
    store.dispatch(LOG,{level:'s',msg})
}

export const logWarning = function (msg) {
    store.dispatch(LOG,{level:'w',msg})
}

export const logError = function (msg) {
    store.dispatch(LOG,{level:'e',msg})
}

export const ellipsis = function (txt, length, withDots= true) {
    if(!txt) return '-'
    return txt.length > length ? txt.substring(0, length - 1) + ( withDots ? '...' : '' ) : txt;
}


export const checkStatusProcessing = (status) => {
    return ['PENDING','PROCESSING'].includes(status)
}

export const checkStatusCompleted = (status) => {
    return ['COMPLETED'].includes(status)
}

export const checkStatusFinished = (status) => {
    return ['CONFIRMED','ERROR'].includes(status)
}

export const checkStatusConfirmed = (status) => {
    return ['CONFIRMED'].includes(status)
}



/**
 Problem:
 You have a javascript array that likely has some duplicate values and you would like a count of those values.
 Solution:
 Try this schnippet out.
 */


export const compressArray = function(original) {

    var compressed = [];
    // make a copy of the input array
    var copy = original.slice(0);

    // first loop goes over every element
    for (var i = 0; i < original.length; i++) {

        var myCount = 0;
        // loop over every element in the copy and see if it's the same
        for (var w = 0; w < copy.length; w++) {
            if (original[i] == copy[w]) {
                // increase amount of times duplicate is found
                myCount++;
                // sets item to undefined
                delete copy[w];
            }
        }

        if (myCount > 0) {
            var a = new Object();
            a.value = original[i];
            a.count = myCount;
            compressed.push(a);
        }
    }

    return compressed;
};




