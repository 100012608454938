// AUTH MODULE
export const CHECK_AUTH = 'checkAuth'
export const LOGIN = "login";
export const LOGOUT = "logout";
export const ON_FORBIDDEN = "onForbidden"
export const LOG = "log";

// CLAIM MODULE
export const LOAD_ACTIVE_CLAIM = 'loadActiveClaim'
export const PUSH_CLAIM = 'pushClaim'
export const SAVE_CLAIM_ID = 'saveClaimId'
export const SAVE_PROFILE = 'saveProfile'
export const SAVE_INVOICE = 'saveInvoice'
export const GO_TO_STEP = 'goToStep'
export const RESET_CLAIM = 'resetClaim'
export const SAVE_SIMULATION = 'saveSimulation'

// ENUMS
export const STEPS_ENUM = {
    PROFILE: 1,
    INVOICES: 2,
    SIMULATION: 3,
    END: 4
}
