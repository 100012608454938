import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import claims from "./claims.module";

import {LOG} from "./actions.type";

Vue.use(Vuex);

//state
const state = {
};

//mutations
const actions = {
  [LOG](context, opt) {
    const {level,msg} = opt
    this._vm.$toastr[level](msg);
  },
}

const getters = {}

const mutations = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    claims
  }

});
