<template>
  <span class="my-tag" :class="{ [`my-tag-${variant}`]: true }">

    <span class="my-tag-value">
      <slot></slot>
    </span>
  </span>
</template>

<script>


export default {
  props: ['variant'],
  name: 'Tag',
  components: {},
  data: () => ({}),
  watch: {},
  methods: {},
  async mounted() {}
}
</script>
<style>

.my-tag-danger {
  background: rgba(255, 208, 206, 0.4);
  color: #b32b23;
}

.my-tag-grey {
  background: #d7d7d736;
  color: #969696;
}

.my-tag-white {
  background: white;
  color: black;
}

.my-tag-yellow {
  background: #efff002e;
  color: #696424;
}


.my-tag-success {
  background: #c6eee1;
  color: #0b825a;

}

.my-tag-warning {
  background: rgba(255, 208, 206, 0.4);
  color: #ae510f;
}

.my-tag-darkblue {
  background: rgba(186, 216, 255, 0.95);
  color: #0039b5;

}

.my-tag-primary {
  background: rgba(239, 246, 255, 0.95);
  color: #2563eb;
}

.my-tag {


  font-size: 0.75rem;
  font-weight: 700;
  padding: 0 0;
  border: 1px solid;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "reclamatec", serif;
}

</style>


