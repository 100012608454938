<template>
    <b-navbar fixed="top" toggleable="sm" style="background-color: white !important;" type="light" variant="light" >
        <b-navbar-brand href="#">
          <router-link :to="'/'">
            <div class="d-flex flex-column border" >
                  <img class="" src="@/assets/logo.jpg" alt="" width="80" height="25" />
            </div>
          </router-link>

        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav style="font-size: 11px">
            <b-navbar-nav >

              <b-nav-item>
                <router-link :to="'/'"><b-icon icon="house-door" aria-hidden="true"></b-icon> Reclamaciones </router-link>
              </b-nav-item>


              <b-nav-item>
                <router-link :to="'/test'">
                  <b-icon icon="laptop" aria-hidden="true"></b-icon> Test
                </router-link>
              </b-nav-item>

              <b-nav-item>
                  <router-link :to="'/demo'">
                      <b-icon icon="file-earmark-medical" aria-hidden="true"></b-icon> Demo OCR
                  </router-link>
              </b-nav-item>


            </b-navbar-nav>

        </b-collapse>


        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

            <b-nav-item-dropdown class="nav-link-gennera" v-if="isAuthenticated" right>
                <!-- Using 'button-content' slot -->
                <template v-slot:button-content>
                    <b-icon icon="person-fill" class="mr-2" style="color: black; font-size: large" aria-hidden="true"></b-icon>
                    <span style="color: black; font-size: small">{{user.username}}</span>
                </template>
                <b-dropdown-item @click="logout">Cerrar sesión</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>

    </b-navbar>



</template>

<script>

    import {mapGetters} from "vuex";
    import {LOGOUT} from "../store/actions.type";

    export default {
        name: "Navbar",

        computed: {
            ...mapGetters(["isAuthenticated","isAdmin","user"]),
        },

        methods: {
            logout(){
                this.$store.dispatch(LOGOUT)
            }
        },
        
       
    }
</script>

<style scoped>

    .router-link-exact-active {
        color: black;
        font-weight: bold;
    }

    a {
        color: #888;
    }

    .logo-title {
        color: black;
        font-size: 11px;
        font-weight: bold;
    }


    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
        color: red;
    }

    .navbar {
        font-size: small;
        padding: 0px;
        padding-left: 5px ;
        border-bottom: 1px solid #ddd;
    }

     .logo-version {
        font-size: 11px;
        font-weight: bold;
        color: #34b944;
    }

    

</style>